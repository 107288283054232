<template>
  <report-layout title="Backups and maintenance">
    <p slot="desc">
      We take twice-daily backups of your website and database to ensure we
      always have a fresh copy of your data, just in case its needed.
    </p>
    <p slot="desc">
      In {{ $report.timeframe }}, we took
      <strong>{{ $report.totalFileSnapshots }} file backup(s)</strong> and
      <strong>{{ $report.totalDbSnapshots }} database snapshot(s)</strong>.
    </p>

    <template slot="default">
      <div class="column is-12">
        <vessels v-model="openVessel">
          <vessel>
            <p slot="topLeft">
              <strong>File backups</strong>
            </p>
            <p slot="bottomLeft">
              {{ $report.totalFileSnapshots }} snapshot(s) taken
              {{
                $report.totalFileSnapshots
                  ? ` – average size ${$humanFileSize(
                      $report.fileSnapshotsAvgSize * 1024
                    )}`
                  : ``
              }}
            </p>
            <template slot="body">
              <file-snapshots-table />
            </template>
            <b-icon
              slot="action"
              slot-scope="actionProps"
              :icon="actionProps.isOpen ? 'chevron-up' : 'chevron-down'"
              size="is-small"
            />
          </vessel>
          <vessel>
            <p slot="topLeft">
              <strong>Database backups</strong>
            </p>
            <p slot="bottomLeft">
              {{ $report.totalDbSnapshots }} snapshot(s) taken
              {{
                $report.totalDbSnapshots
                  ? ` – average size ${$humanFileSize(
                      $report.dbSnapshotsAvgSize * 1024
                    )}`
                  : ``
              }}
            </p>
            <template slot="body">
              <db-snapshots-table />
            </template>
            <b-icon
              slot="action"
              slot-scope="actionProps"
              :icon="actionProps.isOpen ? 'chevron-up' : 'chevron-down'"
              size="is-small"
            />
          </vessel>
        </vessels>
      </div>
    </template>
  </report-layout>
</template>

<script>
export default {
  name: "SiteReportMaintenance",
  inject: ["$reportProvider"],
  components: {
    "report-layout": () => import("./_layout"),
    "file-snapshots-table": () => import("./_reportFileSnapshotsTable"),
    "db-snapshots-table": () => import("./_reportDbSnapshotsTable")
  },
  data() {
    return {
      openVessel: 0
    };
  },
  computed: {
    $report() {
      return this.$reportProvider();
    }
  }
};
</script>
